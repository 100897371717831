/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config.settings.supportedLanguages = ['eu'];
  config.settings.defaultLanguage = 'eu';
  // config.settings.devProxyToApiPath= 'http://localhost:8080/Plone',
  config.settings.navDepth = 2;
  // config.settings.showSelfRegistration = true;
  config.settings.useEmailAsLogin = true;
  config.blocks.requiredBlocks = [];
  config.settings.cookiebotDomainGroupId =
    '96ef58be-2d6d-40cf-ae79-cdd4fd30de30';
  return config;
}
